*::selection {
  background: #e22658;
  color: #fff;
}


.avoidScroll {
  overflow: hidden;
  ;
}



html {
  scroll-behavior: smooth;
}

.appbar {
  background: none;
}






.container {
  text-align: center;
}

@media (max-width: 599px) {
  .logo-link {
    margin: auto;
  }

}

.logo {
  margin: 5px;
  width: auto;
  max-height: 40px;
  height: 100%;
}




/* ### Menu ### */
.active_link,
.search_displayed {
  color: #e22658 !important;
  font-weight: 700;
}



.z-menu ul .mega-menu {
  position: absolute;
  display: flex;
  top: 64px;
  left: 0;
  width: 100%;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  padding: 0 10px;
  background-color: #333;
  transition: opacity 150ms, visibility 150ms;
  transition-delay: 250ms;
}

.z-menu ul li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  transition: 150ms;
}


.z-menu>ul {
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
}


.z-menu li {
  list-style: none;
  padding: 0 10px;
  height: 100%;
  white-space: nowrap;
}


.z-menu>ul>li>a {
  line-height: 64px;
  color: #ccc;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s linear, background 0.2s linear,
    opacity 0.2s linear;

}

.z-menu>ul>li:hover>a,
li:hover>svg {
  color: #e22658 !important;
}

a,
a:hover,
a.active,
a:visited,
a:focus {
  color: #fefefe;
  text-decoration: none;
}







#page-container {
  position: relative;
  min-height: 100vh;
}


@media (min-width: 900px) {
  #page-container {
    padding-bottom: 20rem;
  }
}

@media (max-width: 900px) {
  #page-container {
    padding-bottom: 30rem;
  }
}

@media (max-width: 600px) {
  #page-container {
    padding-bottom: 40rem;
  }
}

media-q .content-wrap {
  padding: 1rem;
  padding-bottom: 20rem;
  /* Footer height */
}


#footer {
  padding: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #333;
}

img.imgMainP {
  /* max-height: 300px; */
  width: 100%;
}

/* rządowe */

.efr img {
  max-height: 150px;
  width: auto;
}

.cta {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: solid 5px rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 200ms;
}

.cta:hover {
  background: rgba(255, 255, 255, 0.2);
  border: solid 5px white
}




.chip {
  cursor: pointer;
  transition: all 200ms ease-out;
}

.chip:hover,
.chip:hover svg {
  color: #e22658 !important;
  border-color: #e22658 !important;
}


#about ul li::marker {
  color: #e22658 !important;
}

.offer-button {
  background: transparent;
  color: white;
  border: solid 3px #e22658 !important;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 200ms;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}


.offer-button:hover {
  transform: translateY(-5px) scale(1.05);
}