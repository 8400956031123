.articleTemplate {
    margin-top: 2rem;
    ;

    .PriceLabel {
        padding: 15px !important;
        text-align: center;
        border-radius: 5px;
        max-width: 49%;
        max-height: 100px;
        font-weight: 700;
        margin: 20px 0;

        &__PricePerDay {
            background: #e22658;
        }

        &__deposit {
            background: #515151;
        }

        &__price {
            display: inlineblock;
            font-size: 2.5rem;
            line-height: 2.5rem;

            &--PricePerDay {}

            &--deposit {}
        }

        &__name {
            display: block;
            font-size: 1.5rem;
            line-height: 1.5rem;


            &--PricePerDay {
                color: #991a3c;
            }

            &--deposit {
                color: #2a2a2a;
            }
        }
    }

    .deposit {
        &__price {}

        &__name {}
    }



    &___benefits {
        margin-bottom: 2rem;
    }

    .categoryLink {
        &:hover {
            text-decoration: underline;
        }
    }
}


.kalendarz {
    max-width: 100%;
}

.image-gallery {
    max-width: 100%;
}


// xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536
.image-gallery-image {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    // @media screen and (min-width: 900px) {
    //     height: 600px;
    // }
}

.image-gallery-icon:hover {
    color: #e22658;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    border-color: #e22658;
}


iframe {
    border: none;
    min-height: 650px;
    width: 100%;
    height: 100%;
}


.view-calendar {

    .widget-header {
        margin-bottom: 0;
    }

    widget-desktop-date-range-view {
        .reservation-form {
            // do wypełnienia nad kalendarzem
        }

        .calendar {
            .desktop-date-range {
                .md-drppicker drops-undefined-undefined.ltr.shown.inline.double {
                    font-size: 10rem;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .desktop-date-range .md-drppicker {
        background-image: linear-gradient(to bottom, #e22658 47px, rgba(0, 0, 0, 0) 0) !important;
    }
}

.desktop-date-range .md-drppicker {
    background-image: linear-gradient(to bottom, rgb(var(--secondary-background-color, 245, 245, 245)) 56px, rgba(0, 0, 0, 0) 0) !important;
}