thead>th.table-header {
    font-size: 1.3rem;
    font-weight: bold;
}

tr {
    &:nth-of-type(2n+1) {
        background: #212121;
    }

    &:hover {
        background-color: lighten($color: #212121, $amount: 3%);
    }

}