@import '../../variables.scss';

.searchIcon {
    transition: color 0.2s linear, background 0.2s linear,
        opacity 0.2s linear;
    transform: translateY(50%);
    cursor: pointer;
    ;

    &:hover {
        color: $primary-color;
    }
}

.search-box {
    width: 700px;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: auto;

    &__mediaImage {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        transition: all 0.2s ease-out;
    }
}


.search-result {
    white-space: break-spaces !important;
    cursor: pointer;

    &__media,
    &__text {
        background-color: white;
        height: 80px;
        width: 80px;
        padding: 5px;
    }

    &__mediaImage {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        transition: all 0.2s ease-out;
    }
}