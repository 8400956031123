.articleTileList {
    & .articleTile {
        height: 100%;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid transparent;
        background-image: linear-gradient(#232323, #232323),
            linear-gradient(45deg, #e22658 0%, #232323 30%, #232323 70%,
                #e22658 100%);
        background-origin: border-box;
        background-clip: padding-box, border-box;
        border-radius: 5px;
        background-size: 200% 100%;
        transition: background-position 0.4s ease-out;
        background-position: 100% center;

        &:hover {
            background-position: 0 0;
        }

        &__content {

            height: calc(100% - 300px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.articleTile {
    & &__media {
        // height: 300px;
        display: flex;
        justify-content: center;
        background-color: white;
        transition: all .2s;
        overflow: hidden;
        height: 300px;
    }

    &__mediaImage {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        transition: all 0.2s ease-out;
    }
}

.articleTile:hover .articleTile__mediaImage {

    transform: scale(1.05);

}